import type { APINotificationResult } from '@hokify/shared-components-nuxt3/lib/types/notificationTypes';
import { diff } from '@hokify/shared-components-nuxt3/lib/helpers/datehelpers/diff';
import { defineStore } from 'pinia';
import { useUiStateStore } from './uistate';

interface IState {
	notifications: APINotificationResult[];
	lastNotificationsApiCall?: Date;
	lastUnseenNotificationsApiCall?: Date;
}

export const useNotificationsStore = defineStore('notifications', {
	state: (): IState => ({
		notifications: []
	}),
	getters: {
		allNotifications(state): APINotificationResult[] {
			return state.notifications;
		}
	},
	actions: {
		async loadNotifications() {
			try {
				const notifications = await this.$nuxt.$hokFetch<Promise<APINotificationResult[]>>(
					'/app-api/notification/all',
					{
						params: { limit: 10, mode: 'user' }
					}
				);
				const hasUnseenNotifications =
					diff(notifications?.[0]?.date, 'hours', new Date()) < 24 && !notifications[0].opened;
				const uiStateStore = useUiStateStore();

				this.setLastNotificationsApiCall();
				this.saveAllNotifications(notifications);
				uiStateStore.updateUnseenNotifications(hasUnseenNotifications);
			} catch (err) {
				console.log('notiferr', err);
			}
		},
		async checkForNewNotifications() {
			const reloadTimeoutForNotifications = new Date();
			reloadTimeoutForNotifications.setTime(
				reloadTimeoutForNotifications.getTime() - 60 * 10 * 1000
			); // 10 minutes ago
			const uiStateStore = useUiStateStore();

			if (
				!this.lastUnseenNotificationsApiCall ||
				this.lastUnseenNotificationsApiCall < reloadTimeoutForNotifications
			) {
				const notifications = await this.$nuxt.$hokFetch<Promise<APINotificationResult[]>>(
					'/app-api/notification/all',
					{
						params: { limit: 1, mode: 'user' }
					}
				);

				const hasUnseenNotifications =
					diff(notifications?.[0]?.date, 'hours', new Date()) < 24 && !notifications[0].opened;

				this.setLastUnseenNotificationsApiCall();
				uiStateStore.updateUnseenNotifications(hasUnseenNotifications);
			}
		},
		async disableNotificationType(type: string) {
			await this.$nuxt.$hokFetch(`/app-api/notification/disable/${type}`, { method: 'POST' });
		},
		setLastNotificationsApiCall() {
			this.lastNotificationsApiCall = new Date();
		},
		setLastUnseenNotificationsApiCall() {
			this.lastUnseenNotificationsApiCall = new Date();
		},
		saveAllNotifications(notifications) {
			this.notifications = notifications;
		}
	}
});
